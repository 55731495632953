import { Language } from 'redux/utils/enums'
import { KeyValueRecord } from 'utils/types'

export const APP_NAME = 'IMCMEXICO'
export const APP_VERSION = '2.0.0'
export const APP_URL = 'https://maquinariagringa.com'

// TODO: Update the "Google ID" with Dr. Carreola's acocunt
// https://console.cloud.google.com/apis/credentials/oauthclient
export const GOOGLE_ID = '402319762707-058f1nngjouatfolvu4rbtc4ng0nbanl.apps.googleusercontent.com'

// TODO: Update the "Email JS" data with Dr. Carreola's acocunt
export const EMAIL_JS_SERVICE_ID = 'service_4dcz322'
export const EMAIL_JS_GENERAL_TEMPLATE_ID = 'template_0tumocg'
export const EMAIL_JS_MACHINE_QUOTATION_TEMPLATE_ID = 'template_042j5qh'
export const EMAIL_JS_PUBLIC_KEY = 'YFp4ExpPdZjQh9_CR'

export const STORAGE_KEY_USER_PROFILE = 'user-profile'

export const MAX_IMAGES_ALLOWED = 4

export const DROPDOWN_UNSELECTED_OPTION = '-1'

export const TABLE_ITEMS_PER_PAGE = 20

export const DATETIME_FORMATS = {
  usDateShort: 'MM/DD/YYYY',
  mxDateShort: 'DD/MM/YYYY',
  usDateMedium: 'MMM DD, YYYY',
  mxDateMedium: 'DD-MMM-YYYY',
  usDateLong: 'MMMM DD, YYYY',
  mxDateLong: 'DD [de] MMMM [de] YYYY',
  usDateFull: 'ddd MMMM D, YYYY',
  mxDateFull: 'ddd D [de] MMMM [de] YYYY',
  time12Hours: 'hh:mm a',
  time24Hours: 'HH:mm [hrs]',
  fullDatetime: 'YYYY-MM-DD HH:mm:ss',
}

export const ONE_WEEK = 1000 * 60 * 60 * 24 * 7
export const ONE_DAY = 1000 * 60 * 60 * 24
export const ONE_HOUR = 1000 * 60 * 60
export const ONE_MINUTE = 1000 * 60
export const ONE_SECOND = 1000

export const NUMBER_CHARS = '0123456789'
export const LOWERCASE_CHARS = 'abcdefghijklmnopqrstuvwxyz'
export const UPPERCASE_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
export const SPECIAL_CHARS = '!@#$%^&*()ñÑ'
export const PASSWORD_GENERATOR_VALID_CHARS = `${NUMBER_CHARS}${LOWERCASE_CHARS}${UPPERCASE_CHARS}${SPECIAL_CHARS}`

// App Paths
export const BASE_PATH = '/'
export const HOME_PATH = 'home'
export const LOGIN_PATH = {
  [Language.en]: 'login',
  [Language.es]: 'iniciar-sesion'
}
export const SIGNUP_PATH = {
  [Language.en]: 'signup',
  [Language.es]: 'registro',
}
export const FORGOT_PASSWORD_PATH = {
  [Language.en]: 'forgot-password',
  [Language.es]: 'olvido-contrasena',
}
export const PASSWORD_RESET_PATH = {
  [Language.en]: 'password-reset',
  [Language.es]: 'reestablecer-contrasena',
}
export const CODE_PATH = '/:encodedLink'
export const PRIVACY_POLICY_PATH = {
  [Language.en]: 'privacy-policy',
  [Language.es]: 'politica-de-privacidad',
}
export const MACHINERY_CATALOG_PATH = {
  [Language.en]: 'machinery',
  [Language.es]: 'maquinaria',
}
export const CATEGORY_CATALOG_PATH = '/:categoryName'
export const MANUFACTURER_CATALOG_PATH = '/:manufacturerName'
export const MODEL_CATALOG_PATH = '/:modelName'
export const MACHINE_DETAILS_PATH = '/:referenceId'
export const ABOUT_US_PATH = {
  [Language.en]: 'about-us',
  [Language.es]: 'nosotros',
}
export const FAQ_PATH = {
  [Language.en]: 'faq',
  [Language.es]: 'preguntas-frecuentes',
}
export const PUBLISH_MACHINE_PATH = {
  [Language.en]: 'publish-machine',
  [Language.es]: 'publicar-maquina',
}
export const USERS_PATH = {
  [Language.en]: 'users',
  [Language.es]: 'usuarios',
}
export const USERS_VALIDATE_EMAIL_PATH = {
  [Language.en]: 'validate-email',
  [Language.es]: 'validar-email',
}
export const USERS_HOME_PATH = {
  [Language.en]: 'welcome',
  [Language.es]: 'bienvenida',
}
export const USERS_MY_MACHINERY_PATH = {
  [Language.en]: 'my-machinery',
  [Language.es]: 'mi-maquinaria',
}
export const USERS_REQUESTS_PATH = {
  [Language.en]: 'requests',
  [Language.es]: 'solicitudes',
}
export const USERS_REQUEST_DETAILS_PATH = '/:requestId'
export const USERS_MESSAGES_PATH = {
  [Language.en]: 'messages',
  [Language.es]: 'mensajes',
}
export const USERS_ACCOUNT_PATH = {
  [Language.en]: 'account',
  [Language.es]: 'cuenta',
}

export const GUEST_MENU_MAP = (language: Language): KeyValueRecord<string> => ({
  home: BASE_PATH,
  machinery: `${BASE_PATH}${MACHINERY_CATALOG_PATH[language]}`,
  aboutUs: `${BASE_PATH}${ABOUT_US_PATH[language]}`,
  faq: `${BASE_PATH}${FAQ_PATH[language]}`,
  publishMachine: `${BASE_PATH}${PUBLISH_MACHINE_PATH[language]}`,
  signUp: `${BASE_PATH}${SIGNUP_PATH[language]}`,
  signIn: `${BASE_PATH}${LOGIN_PATH[language]}`,
  forgotPassword: `${BASE_PATH}${FORGOT_PASSWORD_PATH[language]}`,
})

export const USERS_MENU_MAP = (language: Language): KeyValueRecord<string> => ({
  usersHome: `${BASE_PATH}${USERS_HOME_PATH[language]}`,
  machinery: `${BASE_PATH}${MACHINERY_CATALOG_PATH[language]}`,
  publishMachine: `${BASE_PATH}${PUBLISH_MACHINE_PATH[language]}`,
  users: `${BASE_PATH}${USERS_PATH[language]}`,
  myMachinery: `${BASE_PATH}${USERS_MY_MACHINERY_PATH[language]}`,
  requests: `${BASE_PATH}${USERS_REQUESTS_PATH[language]}`,
  messages: `${BASE_PATH}${USERS_MESSAGES_PATH[language]}`,
  account: `${BASE_PATH}${USERS_ACCOUNT_PATH[language]}`,
})

// Photo Paths
export const BASE_PHOTO_PATH = 'https://allie-health-user-images.s3.amazonaws.com/photos'

// Components Heights
export const HEADER_HEIGHT_DESKTOP = 72
export const HEADER_HEIGHT_MOBILE = 48
export const BOTTOM_FOOTER_HEIGHT_DESKTOP = 40
export const BOTTOM_MENU_HEIGHT_MOBILE = 48

export const SIDE_MENU_WIDTH = 280

// Social Media
export const CELL_NUMBER = '+525552084123'
export const WHATSAPP_BASE_URL_MOBILE = 'https://api.whatsapp.com/send'
export const WHATSAPP_BASE_URL_DESKTOP = 'https://wa.me/'
export const FACEBOOK_BASE_URL = `https://www.facebook.com/sharer/sharer.php?u=${APP_URL}`
export const TWITTER_BASE_URL = `http://www.twitter.com/share?url=${APP_URL}`
