import { DeviceVisibility, GuestsMenuOption, NotificationType, UsersMenuOption } from 'components/utils/enums'
import { AlertDialogProps, MenuOption } from 'components/utils/types'
import { isIOS } from 'react-device-detect'
import {
  FaHome as AndroidHomeIcon,
  FaIdCard as AboutUsIcon,
  FaPowerOff as SignOutIcon,
  FaQuestionCircle as FAQIcon,
  FaUserCircle as AccountIcon,
  FaUserEdit as SignUpIcon,
  FaUserShield as SignInIcon,
  FaUsers as UsersIcon,
} from 'react-icons/fa'
import {
  GiMineTruck as MachineryIcon,
  GiBulldozer as MyMachinesIcon,
} from 'react-icons/gi'
import { IoMdChatboxes as MessagesIcon } from 'react-icons/io'
import {
  IoHome as AppleHomeIcon,
  // IoLanguage as LanguageIcon,
  IoMegaphone as PublishIcon,
} from 'react-icons/io5'
import { MdBusinessCenter as BusinessIcon } from 'react-icons/md'

const HomeIcon = isIOS ? AppleHomeIcon : AndroidHomeIcon

export const GUESTS_MENU_OPTIONS: MenuOption[] = [
  {
    id: GuestsMenuOption.machinery,
    icon: MachineryIcon,
    deviceVisibility: DeviceVisibility.all,
  },
  {
    id: GuestsMenuOption.aboutUs,
    icon: AboutUsIcon,
    deviceVisibility: DeviceVisibility.all,
  },
  {
    id: GuestsMenuOption.faq,
    icon: FAQIcon,
    deviceVisibility: DeviceVisibility.all,
  },
  {
    id: GuestsMenuOption.publishMachine,
    icon: PublishIcon,
    deviceVisibility: DeviceVisibility.all,
  },
  {
    id: GuestsMenuOption.signUp,
    icon: SignUpIcon,
    deviceVisibility: DeviceVisibility.all,
  },
  {
    id: GuestsMenuOption.signIn,
    icon: SignInIcon,
    deviceVisibility: DeviceVisibility.all,
  },
]

export const USERS_MENU_OPTIONS: MenuOption[] = [
  {
    id: UsersMenuOption.home,
    icon: HomeIcon,
    deviceVisibility: DeviceVisibility.desktop,
  },
  {
    id: UsersMenuOption.machinery,
    icon: MachineryIcon,
    deviceVisibility: DeviceVisibility.all,
  },
  {
    id: UsersMenuOption.publishMachine,
    icon: PublishIcon,
    allowedPrivilegeIds: [1, 2, 3, 4, 5],
    deviceVisibility: DeviceVisibility.all,
  },
  {
    id: 'separator1',
    isSeparator: true,
    deviceVisibility: DeviceVisibility.all,
  },
  {
    id: UsersMenuOption.myMachinery,
    icon: MyMachinesIcon,
    allowedPrivilegeIds: [5],
    deviceVisibility: DeviceVisibility.all,
  },
  {
    id: UsersMenuOption.requests,
    icon: BusinessIcon,
    deviceVisibility: DeviceVisibility.desktop,
  },
  {
    id: UsersMenuOption.messages,
    icon: MessagesIcon,
    useBadge: true,
    deviceVisibility: DeviceVisibility.desktop,
  },
  {
    id: UsersMenuOption.users,
    icon: UsersIcon,
    allowedPrivilegeIds: [1, 2, 3, 4],
    deviceVisibility: DeviceVisibility.all,
  },
  {
    id: UsersMenuOption.account,
    icon: AccountIcon,
    deviceVisibility: DeviceVisibility.desktop,
  },
  {
    id: 'separator2',
    isSeparator: true,
    deviceVisibility: DeviceVisibility.all,
  },
  // {
  //   id: UsersMenuOption.switchLanguage,
  //   icon: LanguageIcon,
  //   deviceVisibility: DeviceVisibility.all,
  // },
  {
    id: UsersMenuOption.signOut,
    icon: SignOutIcon,
    deviceVisibility: DeviceVisibility.all,
  },
]

export const MOBILE_USERS_FOOTER_OPTIONS: MenuOption[] = [
  {
    id: UsersMenuOption.home,
    icon: HomeIcon,
    deviceVisibility: DeviceVisibility.mobile,
  },
  {
    id: UsersMenuOption.requests,
    icon: BusinessIcon,
    deviceVisibility: DeviceVisibility.mobile,
  },
  {
    id: UsersMenuOption.messages,
    icon: MessagesIcon,
    useBadge: true,
    deviceVisibility: DeviceVisibility.mobile,
  },
  {
    id: UsersMenuOption.account,
    icon: AccountIcon,
    deviceVisibility: DeviceVisibility.mobile,
  },
]

export const ALERT_DIALOG_PROPS_DEFAULT: AlertDialogProps = {
  isOpen: false,
  title: '',
  type: NotificationType.info,
}