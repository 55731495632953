import {
  KeyboardArrowLeft as PrevIcon,
  KeyboardArrowRight as NextIcon,
} from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import MobileStepper from '@mui/material/MobileStepper'
import { Image, MachineCarouselContainer, Row, SkeletonImage, SkeletonRectangle } from 'components/styledComponents'
import ViewsContainer from 'pages/components/Carousel/MachineDetails/ViewsContainer'
import React, { FC, useState } from 'react'
import { connect } from 'react-redux'
import { LanguageDictionary } from 'redux/utils/language.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'
import { CarouselItem } from 'utils/types'

type Props = {
  auto?: boolean
  dictionary: LanguageDictionary
  height: number | string
  items: CarouselItem[]
  loading?: boolean
  className?: string
}

const UnconnectedMachineCarousel: FC<Props> = ({
  auto,
  dictionary,
  height,
  items,
  loading,
  className,
}) => {
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = items.length
  const finalHeight = typeof height === 'string' ? height : pxToRem(height)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1 === maxSteps ? 0 : prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep === 0 ? maxSteps - 1 : prevActiveStep - 1)
  }

  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }

  return (
    <MachineCarouselContainer className={className}>
      <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
        {loading || !items.length
          ? (
              <>
                <SkeletonImage height={finalHeight} borderBottomRadius={0} />
                <Row groupedByTemplate={`${pxToRem(8)} 1fr ${pxToRem(56)} 1fr ${pxToRem(8)}`}>
                  <span />
                  <SkeletonRectangle height={pxToRem(24)} my={8} />
                  <SkeletonRectangle height={pxToRem(24)} my={8} />
                  <SkeletonRectangle height={pxToRem(24)} my={8} />
                  <span />
                </Row>
              </>
            )
          : (
              <>
                <ViewsContainer
                  auto={auto}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                >
                  {items.map((item: CarouselItem, index: number) => (
                    <Box key={item.id}>
                      {Math.abs(activeStep - index) <= 2
                        ? (
                            <Image
                              src={item.imagePath}
                              alt={item.title}
                              fullCover
                              width="100%"
                              height={finalHeight}
                            />
                          )
                        : null}
                    </Box>
                  ))}
                </ViewsContainer>
                <MobileStepper
                  className="machine-details"
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  backButton={
                    <Button size="small" onClick={handleBack} color="primary" variant="text">
                      <PrevIcon />
                      {dictionary.shared.prev}
                    </Button>
                  }
                  nextButton={
                    <Button size="small" onClick={handleNext} color="primary" variant="text">
                      {dictionary.shared.next}
                      <NextIcon />
                    </Button>
                  }
                />
              </>
            )
        }
      </Box>
    </MachineCarouselContainer>
  )
}

const mapStateToProps = ({ languageStore }: ReduxStore) => {
  const { dictionary } = languageStore

  return {
    dictionary,
  }
}

export const MachineCarousel = connect(
  mapStateToProps
)(UnconnectedMachineCarousel)
