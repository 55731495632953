import { Link, Typography } from '@mui/material'
import { BodyContainer, CustomBreadcrumbs } from 'components/styledComponents'
import { useCleanBasePath } from 'hooks'
import React, { FC, ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { updateLocation } from 'redux/actions/location'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { LocationState } from 'redux/utils/location.types'
import { ReduxStore } from 'redux/utils/types'
import { APP_NAME, APP_URL } from 'utils/constants'
import { getPageFromPath } from 'utils/libs'
import { KeyValueRecord, Route } from 'utils/types'

type Props = {
  children: ReactNode
  dictionary: LanguageDictionary
  language: Language
  categoriesMap?: KeyValueRecord<string>
  manufacturersMap?: KeyValueRecord<string>
  modelsMap?: KeyValueRecord<string>
  isSignedIn?: boolean
  ogDescription?: string
  ogImage?: string
}

const PageContainer: FC<Props> = ({
  children,
  dictionary,
  language,
  categoriesMap,
  manufacturersMap,
  modelsMap,
  isSignedIn,
  ogDescription = '',
  ogImage = `${APP_URL}/IMC_Logo.png`,
}) => {
  const { pathname } = useLocation() || {}
  const page: Route | undefined = getPageFromPath(language, dictionary, pathname)
  const { headerTitle, pageTitle, breadcrumbs } = page || {}
  const pathItems: string[] = useCleanBasePath(pathname).split('/')
  const totalPaths: number = pathItems.length
  let helmetTitle = headerTitle || dictionary.pageNotFound.title

  // Patch for displaying the right breadcrumb label based on the selected machinery.
  if (totalPaths > 1 && ['machinery', 'maquinaria'].includes(pathItems[1]) && breadcrumbs) {
    // Initialize the header title array which will store every item from the machinery.
    const headerTitleItems: string[] = []

    // Initialize the path to be used for the Breadcrumbs.
    let machineryPath = `/${pathItems[1]}`

    for (let index = 2; index < totalPaths; index++) {
      const isLastItem: boolean = index === totalPaths - 1

      if (breadcrumbs[index]) {
        breadcrumbs[index].path = useCleanBasePath(breadcrumbs[index].path || '')
        const urlItem: string = pathItems[index]
        machineryPath += `/${urlItem}`

        let itemTranslation: string = categoriesMap?.[urlItem] || breadcrumbs[index]?.label

        if (index === 3 && manufacturersMap) {
          itemTranslation = manufacturersMap[urlItem]
        } else if (index === 4 && modelsMap) {
          itemTranslation = modelsMap[urlItem]
        } else if (index === 5) {
          itemTranslation = urlItem
        }

        breadcrumbs[index].label = itemTranslation
        breadcrumbs[index].path = isLastItem ? '' : machineryPath

        headerTitleItems.push(itemTranslation)
      }
    }

    helmetTitle = headerTitleItems.length ? headerTitleItems.join(' ') : helmetTitle
  } else if (totalPaths > 2 && ['requests', 'solicitudes'].includes(pathItems[1]) && breadcrumbs) {
    const requestId = pathItems[2]
    breadcrumbs[2].label = requestId
  }

  const title = `${helmetTitle} | ${APP_NAME}`

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={ogDescription} data-react-helmet="true" />
        <meta property="og:title" content={title} data-react-helmet="true" />
        <meta property="og:description" content={ogDescription} data-react-helmet="true" />
        <meta property="og:image" content={ogImage} data-react-helmet="true" />
      </Helmet>
      <BodyContainer isSignedIn={isSignedIn}>
        {!!breadcrumbs && !!breadcrumbs.length && (
          <CustomBreadcrumbs>
            {breadcrumbs.map(item => {
              const { label, path } = item

              if (path) {
                return (
                  <Link key={`${path}${label}`} href={path} variant="caption">{label}</Link>
                )
              }

              return (
                <Typography key={`${path}${label}`} variant="button">{label}</Typography>
              )
            })}
          </CustomBreadcrumbs>
        )}
        <Typography variant="h2">{pageTitle}</Typography>
        {children}
      </BodyContainer>
    </>
  )
}

const mapStateToProps = ({ languageStore, locationStore, machineryStore, sessionStore }: ReduxStore) => {
  const reduxLocation = locationStore
  const { dictionary, language } = languageStore
  const { categoriesMap, manufacturersMap, modelsMap } = machineryStore
  const { isSignedIn } = sessionStore

  return {
    dictionary,
    language,
    reduxLocation,
    categoriesMap,
    manufacturersMap,
    modelsMap,
    isSignedIn,
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateLocation: (newLocation: LocationState) => dispatch(updateLocation(newLocation)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageContainer)