import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { DropdownSingle, PrimaryButton, ToggleSwitch } from 'components/FormFields'
import { FieldMainContainer } from 'components/FormFields/styledComponents'
import {
  LabelWithoutMargin,
  MachineryFilterDropdownsContainer,
  MachinerySortAndFilterButtonContainer,
  Row,
} from 'components/styledComponents'
import { JustifyContent } from 'components/utils/enums'
import { DropdownStructure } from 'components/utils/types'
import { useCleanBasePath } from 'hooks'
import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Language } from 'redux/utils/enums'
import { LanguageDictionary } from 'redux/utils/language.types'
import { GetCategoriesResponse, GetManufacturersResponse, GetModelsResponse } from 'redux/utils/machinery.types'
import { ReduxStore } from 'redux/utils/types'
import { pxToRem } from 'theme/typography'
import { DROPDOWN_UNSELECTED_OPTION } from 'utils/constants'

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    justifyContent: `${JustifyContent.flexEnd}`,
  },
  switchContainer: {
    [theme.breakpoints.up('xs')]: {
      justifyContent: `${JustifyContent.flexEnd}`,
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: `${JustifyContent.flexStart}`,
    },
  }
}))

type Props = {
  dictionary: LanguageDictionary
  language: Language
  categories: GetCategoriesResponse
  categoriesLoading: boolean
  categoryName: string
  manufacturers: GetManufacturersResponse
  manufacturersLoading: boolean
  manufacturerName: string
  models: GetModelsResponse
  modelsLoading: boolean
  modelName: string
  machinesLoading: boolean
  isSortAscending: boolean
  onCategoryChange: (newCategoryName: string) => void
  onManufacturerChange: (newManufacturerName: string) => void
  onModelChange: (newModelName: string) => void
  onSortChange: (isSortAscending: boolean) => void
  onFilterApply: () => void
}

const UnconnectedMachineryFilter: FC<Props> = ({
  dictionary: { machinery: machineryDictionary },
  language,
  categories,
  categoriesLoading,
  categoryName,
  manufacturers,
  manufacturersLoading,
  manufacturerName,
  models,
  modelsLoading,
  modelName,
  machinesLoading,
  isSortAscending,
  onCategoryChange,
  onManufacturerChange,
  onModelChange,
  onSortChange,
  onFilterApply,
}) => {
  const classes = useStyles()
  const { pathname: dirtyPathname } = useLocation() || {}
  const pathname = useCleanBasePath(dirtyPathname)
  const [currentPath, setCurrentPath] = useState<string>('')
  const [categoriesOptions, setCategoriesOptions] = useState<DropdownStructure[]>([])
  const [manufacturersOptions, setManufacturersOptions] = useState<DropdownStructure[]>([])
  const [modelsOptions, setModelsOptions] = useState<DropdownStructure[]>([])

  const pathItems: string[] = pathname.split('/')
  const isCovidCatalog = pathItems.length > 2 && pathItems[2].includes('covid')
  const isCategoryDropdownDisabled: boolean = categoriesLoading || !categoriesOptions.length
  const isCategorySelected: boolean = categoryName !== '-1'
  const isManufacturerDropdownDisabled: boolean = !isCategorySelected || manufacturersLoading || !manufacturersOptions.length
  const isManufacturerSelected: boolean = categoryName !== '-1' && manufacturerName !== '-1'
  const isModelDropdownDisabled: boolean = !isManufacturerSelected || modelsLoading || !modelsOptions.length

  useEffect(() => {
    const hasPathChanged = currentPath !== pathname

    if (categories && !isCovidCatalog && hasPathChanged) {
      const topCategoriesList = categories.topCategories.filter(data => !data.category.es.includes('Covid')).map(data => ({
        label: data.category[language],
        value: data.category[language],
      }))

      const categoriesList = categories.categories.filter(data => !data.category.es.includes('Covid')).map(data => ({
        label: data.category[language],
        value: data.category[language],
      }))

      const newOptions: DropdownStructure[] = [
        {
          label: machineryDictionary.topCategories,
          value: '',
          isGroup: true,
          groupLabelRightSide: topCategoriesList.length.toLocaleString()
        },
        ...topCategoriesList,
        {
          label: machineryDictionary.otherCategories,
          value: '',
          isGroup: true,
          groupLabelRightSide: categoriesList.length.toLocaleString()
        },
        ...categoriesList,
      ]

      setCategoriesOptions(newOptions)
      setCurrentPath(pathname)

      if (pathItems.length === 1 || categoryName.toLowerCase().includes('covid')) {
        onCategoryChange(DROPDOWN_UNSELECTED_OPTION)
      }
    } else if (categories && isCovidCatalog && hasPathChanged) {
      const newOptions: DropdownStructure[] = categories.categories.filter(data => data.category.es.includes('Covid')).map(data => ({
        label: data.category[language],
        value: data.category[language],
      }))

      setCategoriesOptions(newOptions)
      setCurrentPath(pathname)
    }
  }, [categories, currentPath, pathname])

  useEffect(() => {
    const isCategorySelected = !!categoryName && categoryName !== '-1'

    if (isCategorySelected && manufacturers) {
      const topManufacturersList = manufacturers.topManufacturers.map(data => ({
        label: data.manufacturer,
        value: data.manufacturer,
      }))

      const manufacturersList = manufacturers.manufacturers.map(data => ({
        label: data.manufacturer,
        value: data.manufacturer,
      }))

      const newOptions: DropdownStructure[] = [
        {
          label: machineryDictionary.topManufacturers,
          value: '',
          isGroup: true,
          groupLabelRightSide: topManufacturersList.length.toLocaleString()
        },
        ...topManufacturersList,
        {
          label: machineryDictionary.otherManufacturers,
          value: '',
          isGroup: true,
          groupLabelRightSide: manufacturersList.length.toLocaleString()
        },
        ...manufacturersList,
      ]

      setManufacturersOptions(newOptions)
    }
  }, [categoryName, manufacturers])

  useEffect(() => {
    const isCategorySelected = !!categoryName && categoryName !== '-1'
    const isManufacturerSelected = !!manufacturerName && manufacturerName !== '-1'

    if (isCategorySelected && isManufacturerSelected && models) {
      const topModelsList = models.topModels.map(data => ({
        label: data.model,
        value: data.model,
      }))

      const modelsList = models.models.map(data => ({
        label: data.model,
        value: data.model,
      }))

      const newOptions: DropdownStructure[] = [
        {
          label: machineryDictionary.topModels,
          value: '',
          isGroup: true,
          groupLabelRightSide: topModelsList.length.toLocaleString()
        },
        ...topModelsList,
        {
          label: machineryDictionary.otherModels,
          value: '',
          isGroup: true,
          groupLabelRightSide: modelsList.length.toLocaleString()
        },
        ...modelsList,
      ]

      setModelsOptions(newOptions)
    }
  }, [categoryName, manufacturerName, models])

  return (
    <>
      <MachineryFilterDropdownsContainer>
        <DropdownSingle
          id={machineryDictionary.category}
          label={machineryDictionary.category}
          nonValueLabel={!isCovidCatalog ? machineryDictionary.categoryPlaceholder : undefined}
          options={categoriesOptions}
          value={categoryName}
          disabled={isCategoryDropdownDisabled}
          fullWidth
          onChange={onCategoryChange}
        />
        <DropdownSingle
          id={machineryDictionary.manufacturer}
          label={machineryDictionary.manufacturer}
          nonValueLabel={machineryDictionary.manufacturerPlaceholder}
          options={manufacturersOptions}
          value={manufacturerName}
          disabled={isManufacturerDropdownDisabled}
          fullWidth
          onChange={onManufacturerChange}
        />
        <DropdownSingle
          id={machineryDictionary.model}
          label={machineryDictionary.model}
          nonValueLabel={machineryDictionary.modelPlaceholder}
          options={modelsOptions}
          value={modelName}
          disabled={isModelDropdownDisabled}
          fullWidth
          onChange={onModelChange}
        />
      </MachineryFilterDropdownsContainer>
      <MachinerySortAndFilterButtonContainer>
        <Row groupedByTemplate={`1fr ${pxToRem(56)}`} marginBottom={{ xs: pxToRem(8), lg: 0 }}>
          <LabelWithoutMargin variant="body1" ellipsis>
            {machineryDictionary.sortByPrice}
          </LabelWithoutMargin>
          <ToggleSwitch
            className={classes.switchContainer}
            checked={isSortAscending}
            onChange={onSortChange}
          />
        </Row>
        <FieldMainContainer className={classes.buttonContainer}>
          <PrimaryButton
            label={machineryDictionary.filter}
            width={pxToRem(72)}
            loading={machinesLoading}
            onClick={onFilterApply}
          />
        </FieldMainContainer>
      </MachinerySortAndFilterButtonContainer>
    </>
  )
}

const mapStateToProps = ({ languageStore, machineryStore }: ReduxStore) => {
  const { dictionary, language } = languageStore
  const {
    categories,
    categoriesLoading,
    manufacturers,
    manufacturersLoading,
    models,
    modelsLoading,
    machinesLoading,
  } = machineryStore

  return {
    dictionary,
    language,
    categories,
    categoriesLoading,
    manufacturers,
    manufacturersLoading,
    models,
    modelsLoading,
    machinesLoading,
  }
}

const MachineryFilter = connect(
  mapStateToProps,
)(UnconnectedMachineryFilter)

export default MachineryFilter
